import { getUserLocation, placesByLocationName } from '@/api/mapbox';
import { useFocus } from '@/app/_hooks/useFocus';
import Autocomplete from '@/components/autocomplete';
import { AutocompleteItemOption } from '@/components/autocomplete/types';
import { CLIENT_ENV } from '@/config/configuration';
import { DEFAULT_AUTOCOMPLETE_LOCATIONS, LOCATION_ACTUAL_POSITION_STRING } from '@/costants';
import { AUTOCOMPLETE } from '@/costants/enum';
import { useInclinicStore } from '@/store';
import { ELTextField } from '@davincihealthcare/elty-design-system-react';
import { MapPinIcon as MapPinOutlinedIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MapPinIcon as MapPinSolidIcon } from '@heroicons/react/24/solid';
import { useEffect, useMemo, useState } from 'react';

const fetchMapboxSuggestions = async (input: string, accessToken: string, setSuggestionsCb: Function) => {
  if (!input || !accessToken) {
    return;
  }
  if (input.length < 3) {
    setSuggestionsCb([]);
    return;
  }
  const places = await placesByLocationName(input);
  const mapboxSuggestions: AutocompleteItemOption[] = [];
  for (const suggestion of places) {
    if (!suggestion?.place_name_it) {
      continue;
    }
    mapboxSuggestions.push({
      value: suggestion.place_name_it,
      type: AUTOCOMPLETE.LOCATION,
      coordinates: [suggestion?.geometry?.coordinates?.[1], suggestion?.geometry?.coordinates?.[0]],
    });
  }
  setSuggestionsCb(mapboxSuggestions);
};

type LocationProps = React.ComponentPropsWithoutRef<'input'> & {
  setValue: (value: string) => void;
  setLocationCoordinates: (coordinates: number[]) => void;
  inline?: boolean;
};

const Location = ({ setValue, setLocationCoordinates, inline, value, id }: LocationProps) => {
  const { searchForm } = useInclinicStore(state => state);
  const [suggestions, setSuggestions] = useState<AutocompleteItemOption[]>([]);

  const [inputRef, setFocus] = useFocus<HTMLInputElement>();
  const [shouldOpenMenu, setShouldOpenMenu] = useState(false);

  const [prefetchedUserLocation, setPrefetchedUserLocation] = useState<{ userLocationName?: string; userCoordinates?: number[] }>();

  useEffect(() => {
    if (searchForm.location !== value) {
      const debounceTimeoutId = setTimeout(async () => {
        await fetchMapboxSuggestions(String(value), CLIENT_ENV().NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN ?? '', setSuggestions);
      }, 300);
      return () => {
        clearTimeout(debounceTimeoutId);
      };
    }
  }, [value, searchForm.location]);

  const clearInput = () => {
    setValue('');
    setFocus();
    setShouldOpenMenu(true);
  };

  const options = useMemo(() => (String(value) ? suggestions : DEFAULT_AUTOCOMPLETE_LOCATIONS), [value, suggestions]);

  return (
    <Autocomplete
      shouldOpenMenu={shouldOpenMenu}
      setShouldOpenMenu={setShouldOpenMenu}
      options={options}
      setInputValueRef={(input: string, coordinates?: number[]) => {
        if (input === LOCATION_ACTUAL_POSITION_STRING) {
          if (prefetchedUserLocation?.userLocationName && prefetchedUserLocation?.userCoordinates) {
            setValue(prefetchedUserLocation?.userLocationName);
            setLocationCoordinates(prefetchedUserLocation?.userCoordinates);
          } else {
            getUserLocation().then(result => {
              if (!prefetchedUserLocation) {
                setPrefetchedUserLocation(result);
              }
              setValue(result?.userLocationName ?? '');
              setLocationCoordinates(result?.userCoordinates ?? []);
            });
          }
        } else {
          setValue(input);
          setLocationCoordinates(coordinates ?? []);
        }
      }}
      inputValueRef={String(value)}
      variant={inline ? 'header' : 'page'}
      id={id || `autocomplete-${Date.now()}`}
    >
      {inline ? (
        <div className="relative inline-flex h-full w-full items-center text-xs text-slate-500">
          <MapPinSolidIcon className="absolute ml-4 h-5 w-5" />
          <input
            value={value}
            ref={inputRef}
            onChange={e => setValue(e.target.value)}
            placeholder="Dove?"
            className={`font-mendium mr-1 flex h-full w-full items-center truncate bg-gray-100 py-4 pl-11 pr-8 text-sm text-primary placeholder:text-[#627282] md:font-semibold`}
            data-cy="input-location"
          />
          {value && (
            <button
              className="absolute right-4 hidden h-5 w-5 cursor-pointer text-primary peer-focus:text-primary-active md:block"
              onClick={clearInput}
              data-testid="clear-icon"
            >
              <XMarkIcon />
            </button>
          )}
        </div>
      ) : (
        <div>
          <ELTextField
            name="location"
            aria-label="location"
            placeholder="Dove?"
            value={value}
            innerRef={inputRef}
            onChange={e => setValue(e.target.value)}
            leadingIcon={<MapPinOutlinedIcon />}
            data-cy="input-location"
            onClear={value ? clearInput : undefined}
          />
        </div>
      )}
    </Autocomplete>
  );
};

export default Location;
