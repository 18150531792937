'use client';
import { useEffect, useState } from 'react';
import clsx from 'clsx/lite';
import { useInclinicStore } from '@/store';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { ELButton, ELIconButton } from '@davincihealthcare/elty-design-system-react';
import { getStringFromQueryParam } from '@/utils/string';
import Location from './location';
import HealthcareService from './healthcareService';
import { ROUTES, getRoute } from '@/routes';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

type SearchbarProps = {
  inline?: boolean;
  asPlaceholder?: boolean;
  defaultService?: string | null;
  className?: string;
  id: string;
};

const Searchbar = ({ id, inline = false, asPlaceholder = false, className, defaultService }: SearchbarProps) => {
  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const { searchForm, updateHealthcareService, updateLocation } = useInclinicStore(state => state);
  const [healthcareService, setHealthcareService] = useState('');
  const [location, setLocation] = useState('');
  const [locationCoordinates, setLocationCoordinates] = useState<number[]>();
  const updateStore = (newHealthcareService: string, newLocation: string) => {
    updateHealthcareService(newHealthcareService);
    updateLocation(newLocation);
  };

  const updateInputFields = (newHealthcareService: string, newLocation: string) => {
    setHealthcareService(newHealthcareService);
    setLocation(newLocation);
  };

  const search = () => {
    if (!healthcareService && !location) {
      return;
    }
    updateStore(healthcareService, location);

    const newParams = new URLSearchParams(searchParams?.toString());
    const lat = locationCoordinates?.[0];
    const lng = locationCoordinates?.[1];

    newParams.delete('page');
    newParams.delete('userGeolocationActive');
    newParams.set('location', location);
    newParams.set('service', healthcareService);
    newParams.set('lat', lat ? `${lat}` : '');
    newParams.set('lng', lng ? `${lng}` : '');

    router.push(`${ROUTES.BOOKING.pathname}?${newParams.toString()}`);
  };

  useEffect(() => {
    if (!searchParams) return;
    const currentRoute = getRoute(pathName ?? '');
    const newHealthcareService =
      typeof defaultService === 'string'
        ? defaultService
        : getStringFromQueryParam(searchParams.get('service') ?? undefined) || searchForm?.healthcareService || '';

    if (currentRoute.pathname === ROUTES.BOOKING.pathname && !searchParams.get('location')) {
      updateStore(newHealthcareService, '');
      updateInputFields(newHealthcareService, '');
    } else {
      const newLocation = getStringFromQueryParam(searchParams.get('location') ?? undefined) || searchForm?.location || '';
      updateStore(newHealthcareService, newLocation);
      updateInputFields(newHealthcareService, newLocation);
    }
    setLocationCoordinates([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pathName, defaultService]);

  return (
    <div className={clsx('relative flex items-center', inline ? 'h-10 rounded-6xl bg-gray-100' : 'mt-5 flex-col gap-y-5', className)}>
      <HealthcareService value={healthcareService} setValue={setHealthcareService} inline={inline} id={id} />
      {inline && (
        <div
          className={inline ? 'relative h-6 w-0.5 bg-[#627282]' : 'hidden md:relative md:block md:h-10 md:w-px md:bg-[#627282] lg:h-12'}
        />
      )}
      <Location value={location} setValue={setLocation} setLocationCoordinates={setLocationCoordinates} inline={inline} id={id} />
      {/* desktop */}
      {inline && !asPlaceholder && (
        <div className="mr-1.5">
          <ELIconButton aria-label="cerca" variant="filled" color="secondary" onClick={search} size="medium">
            <MagnifyingGlassIcon />
          </ELIconButton>
        </div>
      )}
      {/* mobile inline as placeholder */}
      {inline && asPlaceholder && (
        <div className="mr-1.5 inline-block rounded-full bg-secondary p-1.5 text-white hover:bg-secondary-hover active:bg-secondary-pressed">
          <div className="flex items-center">
            <span className="h-4 w-4">
              <MagnifyingGlassIcon />
            </span>
          </div>
        </div>
      )}
      {/* mobile collapsible menu */}
      {!inline && !asPlaceholder && (
        <ELButton
          variant="filled"
          color="secondary"
          onClick={search}
          label="CERCA"
          aria-label="cerca"
          leadingIcon={<MagnifyingGlassIcon />}
          fullWidth
          data-cy="button-searchbar"
        />
      )}
    </div>
  );
};

export default Searchbar;
